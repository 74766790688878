import React from 'react';
import me from '../assets/me.jpg';

function Hero() {
  return (
    <section className="py-20 flex items-center">
      <div className="w-1/2">
        <h2 className="text-4xl font-bold mb-4">Hello! I'm Shyan-Yong</h2>
        <p className="text-xl mb-6">I'm a Computer Science Undergraduate currently studying in NUS.</p>
      </div>
      <div className="w-1/2 flex justify-center">
        <div className="w-64 h-64 bg-blue-200 rounded-full flex items-center justify-center overflow-hidden">
          <img src={me} alt="Shyan-Yong" className="object-cover w-full h-full rounded-full" />
        </div>
      </div>
    </section>
  );
}

export default Hero;
