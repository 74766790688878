import React from "react";
import { NavLink } from "react-router-dom";
import Routes from "../utils/routes";

const NavBar = () => {
    return (
        <nav className="px-4 py-6 " aria-label='Top'>
            <div className="flex w-full items-center justify-between">
                <NavLink to={Routes.home} className='font-montserrat text-2xl font-bold'>
                    Shyan
                </NavLink>
                <div className="ml-10 flex justify-between space-x-3 md:space-x-6">
                    <NavLink 
                        to={Routes.about} 
                        className={`font-montserrat text-xl font-bold`}
                        >
                        About
                    </NavLink>
                    <NavLink 
                        to={Routes.experience} 
                        className={`font-montserrat text-xl font-bold`}
                        >
                        Experience
                    </NavLink>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
