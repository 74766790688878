import React from 'react';

function Experience({ title, company, date, description }) {
  return (
    <div className="mb-8">
      <h3 className="text-2xl font-semibold">{title}</h3>
      <p className="text-lg text-gray-600">{company} | {date}</p>
      <p className="mt-2">{description}</p>
    </div>
  );
}

export default Experience;