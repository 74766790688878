import React from 'react';
import { FaLinkedin, FaGithub, FaEnvelope, FaFile } from 'react-icons/fa';
import resume from '../assets/Resume - ShyanYong.pdf';

function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4 flex justify-center space-x-8">
        <a href="https://www.linkedin.com/in/shyan-yong/" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-blue-400">
          <FaLinkedin />
        </a>
        <a href="https://github.com/shyanyong" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-gray-400">
          <FaGithub />
        </a>
        <a href="mailto:shyannyongg@gmail.com" className="text-2xl hover:text-red-400">
          <FaEnvelope />
        </a>
        <a href={resume} target='_blank' rel="noopener noreferrer" className="text-2xl hover:text-red-400">
          <FaFile />
        </a>
      </div>
    </footer>
  );
}

export default Footer;