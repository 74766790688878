import React from 'react';

function ProjectCard({ title, description }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p>{description}</p>
    </div>
  );
}

function Projects() {
  const projects = [
    { title: "AdvenSure", description: "AdvenSure is a travel planning application designed to simplify and enhance the trip planning experience for adventure enthusiasts. Developed using Next.js, JavaScript, and Firebase, AdvenSure offers a centralised hub where travellers can create personalised itineraries by inputting destinations, dates, and activities. The application also features to-do lists with deadlines, reminders for upcoming reservations, and the ability to share itineraries with others, making it perfect for both individual and group travellers. Users can store and manage their past, present, and future trips within their profiles, and explore other users' itineraries for inspiration. The platform aims to make travel planning an easier, more organised, and enjoyable process for everyone." },
    { title: "ChatBot", description: "Built with Streamlit and Python, it uses Ollama LLMs that leverages advances NLP and machine learning to categorize and respond to queries." },
  ];

  return (
    <section id="projects" >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {projects.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>
    </section>
  );
}

export default Projects;