import React from 'react';
import Experiences from '../components/Experience';
import Projects from '../components/Projects';
function Experience() {
    return (
        <div className="py-20">
            <section>
                <h2 className="text-3xl font-bold mb-6">Experience</h2>
                <Experiences
                title="Software Engineering Intern"
                company="BlueSg"
                date="May 2024 - Current"
                description="Developed and maintained web applications using React and Node.js. Created internal tools to automate testing processes and improve team efficiency. "
                />
            </section>
            <section>
                <h2 className="text-3xl font-bold mb-6">Projects</h2>
                <Projects />
            </section>
        </div>
    );
}

export default Experience;