import React from 'react';

function About() {
  return (
    <div className="py-20">
      <section className="mb-16">
        <h2 className="text-3xl font-bold mb-6">About Me</h2>
        <p className="text-xl leading-relaxed">
          Hi there! I'm Shyan, a passionate Computer Science undergraduate with an international background. 
          I'm deeply interested in full-stack web development, 
          and I love tackling complex problems with innovative solutions. 
          When I'm not coding, you can find me under the water, scuba diving.
        </p>
      </section>
    </div>
  );
}

export default About;